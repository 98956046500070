
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="学校" prop="schoolId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.schoolId"
            height="200"
          ></el-select-tree>
          <br />
          <span style="color:red">为空则为默认设置</span>
        </el-form-item>
        <el-form-item label="学期名称" prop="termName">
          <el-input v-model="formModel.termName" placeholder="请输入学期名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="激活" prop="active">
          <el-radio-group v-model="formModel.active">
            <el-radio :label="true">激活</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import schoolTermApi from "@/api/base/schoolTerm";
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";

export default {
  props: ["businessKey", "title", "companyResult"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        termName: [
          { required: true, message: "学期名称不能为空", trigger: "blur" },
        ],
        active: [{ required: true, message: "激活不能为空", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  created() {
    this.loadTree();
  },
  methods: {
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return schoolTermApi.add(self.formModel);
            } else {
              return schoolTermApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return schoolTermApi.create();
      } else {
        return schoolTermApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>